import * as React from "react";

import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { StaticImage } from "gatsby-plugin-image";
import { staticImgContainer } from "../../styles/component-stylings";

const PAGE_TITLE = "Recruitment Process";

const CareersRecruitmentProcessPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <StaticImage style={staticImgContainer} src='../../images/afp-office2.jpg' alt='AFP Office' placeholder='blurred' loading='lazy' />
            </PageBlock>
        </PageLayout>
    );
};

export default CareersRecruitmentProcessPage;
